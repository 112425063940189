/* src/app/shared/reflection-tagcloud/reflection-tagcloud.component.scss */
:host {
  display: block;
  margin-bottom: 40px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-container {
  margin-bottom: 6px;
  width: 160px;
  height: 160px;
}
.circle-container.x5 {
  width: 170px;
  height: 170px;
}
.circle-container.x4 {
  width: 160px;
  height: 160px;
}
.circle-container.x3 {
  width: 150px;
  height: 150px;
}
.circle-container.x2 {
  width: 140px;
  height: 140px;
}
.circle-container.x1 {
  width: 125px;
  height: 125px;
}
.circle-container .circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ccc;
  padding: 6px 4px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.circle-container .circle-inner {
  width: 100%;
}
.circle-container .circle .num {
  display: block;
  font-family: var(--font-2);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: -12px;
  text-align: center;
  color: #ffffff;
}
.circle-container .circle .name {
  white-space: nowrap;
  font-family: var(--font-2);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.circle-container .circle .name.loaded {
  white-space: break-spaces;
}
.circle-container .circle.x5 {
  width: 170px;
  height: 170px;
}
.circle-container .circle.x4 {
  width: 160px;
  height: 160px;
}
.circle-container .circle.x3 {
  width: 150px;
  height: 150px;
}
.circle-container .circle.x2 {
  width: 140px;
  height: 140px;
}
.circle-container .circle.x1 {
  width: 125px;
  height: 125px;
}
.circle-container .circle.positive {
  background: #acc5d6;
}
.circle-container .circle.negative {
  background: #cc7e85;
}
/*# sourceMappingURL=reflection-tagcloud.component-DLA26B6A.css.map */
