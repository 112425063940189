<div
  class="message-date"
  *ngIf="
    (authService.currentChat['messages'][index - 1] &&
      authService.currentChat['messages'][index - 1].created_at
      | date : 'mediumDate') !== (message.created_at | date : 'mediumDate')
  "
>
  <span>{{ message.created_at | date : "MMM d, y" }}</span>
</div>
<div
  *ngIf="!isNotification"
  class="{{ authService.dashboardType }} {{
    userId ? 'consultant' : 'app-user'
  }} flex flex-row"
  [class.message-divider]="
    authService.currentChat['messages'][index - 1] &&
    authService.currentChat['messages'][index - 1].user_id !== message.user_id
  "
  [style.justify-content]="align === 'end' ? 'flex-end' : 'flex-start'"
>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div
        *ngIf="
          authService.getCurrentUser() &&
          authService.getCurrentUser().id !== userId
        "
        class="consultant-name"
        [innerHTML]="
          authService.currentChat &&
          authService.currentChat['consultants'] &&
          authService.currentChat['consultants'][userId]
            ? authService.currentChat['consultants'][userId].name
            : ''
        "
      ></div>
      <div
        *ngIf="
          message?.data?.type &&
          (message.data.type === 'unwanted_behavior' ||
            message.data.type === 'unwanted_behaviour')
        "
      >
        <div class="unwanted-behavior">
          <div class="unwanted-behavior-text">
            <i class="material-icons">warning</i>
            <span>Unerwünschtes Verhalten</span>
          </div>
          <div class="unwanted-behavior-type">
            Type:
            {{
              message.data.message_type === "company"
                ? "Corrente soll meinen Arbeitgeber so schnell wie m\u00f6glich informieren."
                : "anonyme Meldung in den Jahresbericht aufnehmen"
            }}!
          </div>
        </div>
      </div>
      <div
        class="appointment-consultant"
        *ngIf="message?.data?.confidant?.consultant"
      >
        <strong>Voorkeur</strong><br />
        <div
          style="
            border: 1px solid #e0e0e0;
            padding: 10px;
            display: flex;
            align-items: center;
            background-color: #f5f5f5;
          "
        >
          <img
            *ngIf="message?.data?.confidant?.consultant?.photo_url"
            [src]="message?.data?.confidant?.consultant?.photo_url"
            alt="Consultant"
            style="
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 10px;
            "
          />
          <div>
            <strong>{{
              message?.data?.confidant?.consultant?.full_name
            }}</strong>
          </div>
        </div>
      </div>

      <div class="appointment" *ngIf="message?.data?.appointment">
        <div class="appointment-consultant">
          <strong>Aanvraag voor afspraak met</strong><br />
          <div
            style="
              border: 1px solid #e0e0e0;
              padding: 10px;
              display: flex;
              align-items: center;
              background-color: #f5f5f5;
            "
          >
            <img
              *ngIf="message?.data?.appointment?.consultant?.photo_url"
              [src]="message?.data?.appointment?.consultant?.photo_url"
              alt="Consultant"
              style="
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
              "
            />
            <div>
              <strong>{{
                message?.data?.appointment?.consultant?.full_name
              }}</strong>
            </div>
          </div>
        </div>
        <div class="appointment-form" style="margin-top: 20px">
          <h3>Gebruikersgegevens</h3>
          <div>
            <div>
              <strong>{{
                "CONFIDANT.MAKE_APPOINTMENT.FIRST_NAME" | translate
              }}</strong
              ><br />
              {{
                message?.data?.appointment?.form?.firstName === ""
                  ? "Niet opgegeven"
                  : message?.data?.appointment?.form?.firstName
              }}
            </div>
            <div>
              <strong>{{
                "CONFIDANT.MAKE_APPOINTMENT.LAST_NAME" | translate
              }}</strong
              ><br />
              {{
                message?.data?.appointment?.form?.lastName === ""
                  ? "Niet opgegeven"
                  : message?.data?.appointment?.form?.lastName
              }}
            </div>
            <div>
              <strong>{{
                "CONFIDANT.MAKE_APPOINTMENT.EMAIL" | translate
              }}</strong
              ><br />
              {{
                message?.data?.appointment?.form?.email === ""
                  ? "Niet opgegeven"
                  : message?.data?.appointment?.form?.email
              }}
            </div>
            <div>
              <strong>{{
                "CONFIDANT.MAKE_APPOINTMENT.PHONE" | translate
              }}</strong
              ><br />
              {{
                message?.data?.appointment?.form?.phone === ""
                  ? "Niet opgegeven"
                  : message?.data?.appointment?.form?.phone
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="confidant" *ngIf="message?.data?.confidant">
        <div *ngIf="message?.data?.confidant?.reportType">
          {{
            "CONFIDANT." +
              confidantChatTypeTranslation[message?.data?.confidant?.reportType]
              | translate
          }}
        </div>
        <div *ngIf="message?.data?.confidant?.reportWords?.length">
          <strong>{{ "CONFIDANT.NEW_REPORT.REPORT" | translate }}</strong>
          <div>
            <div
              *ngFor="let feeling of message?.data?.confidant?.reportWords"
              class="word"
            >
              <span *ngIf="feeling === feeling.toUpperCase()">
                {{ "CONFIDANT.WORDS.REPORT." + feeling | translate }}
              </span>
              <span *ngIf="feeling !== feeling.toUpperCase()">
                {{ feeling }}
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="message?.data?.confidant?.feelingsWords?.length">
          <strong>{{ "CONFIDANT.NEW_REPORT.FEELINGS" | translate }}</strong>
          <div>
            <div
              *ngFor="let feeling of message?.data?.confidant?.feelingsWords"
              class="word"
            >
              <span *ngIf="feeling === feeling.toUpperCase()">
                {{ "CONFIDANT.WORDS.FEELINGS." + feeling | translate }}
              </span>
              <span *ngIf="feeling !== feeling.toUpperCase()">
                {{ feeling }}
              </span>
            </div>
          </div>
        </div>
        <br /><br />
      </div>
      <div
        *ngIf="
          message.data &&
          (message.data.reflection || message.data.goals || message.data.tests)
        "
      >
        <button
          (click)="openData()"
          style="margin-bottom: 24px"
          mat-raised-button=""
          color="primary"
        >
          <mat-icon>description</mat-icon>
          <span *ngIf="message.data.reflection">{{
            "INSIGHTS_SHARE.REFLECTION" | translate
          }}</span>
          <span *ngIf="message.data.goals">{{
            "GOALS.TITLE" | translate
          }}</span>
          <span *ngIf="message.data.tests">{{
            "TESTS.RESULTS" | translate
          }}</span>
        </button>
      </div>
      <div *ngIf="!message.type">{{ message.message }}</div>
      <div *ngIf="message.type">
        <div *ngIf="message.user">
          <strong>{{ message.user }}</strong>
        </div>
        <div [innerHTML]="message.message"></div>
      </div>
      <div class="text-right time">
        {{ message.created_at | date : "MMM d, y HH:mm" }}
        <span *ngIf="e2e">
          <mat-icon
            style="
              font-size: 14px;
              height: 14px;
              width: 16px;
              color: rgba(26, 255, 0, 0.22);
              display: inline;
            "
            aria-hidden="false"
            aria-label="Versleuteld"
            >lock</mat-icon
          >
        </span>
        <!--      <span *ngIf="!e2e">-->
        <!--        <mat-icon-->
        <!--          style="-->
        <!--            font-size: 14px;-->
        <!--            height: 14px;-->
        <!--            width: 16px;-->
        <!--            color: rgba(255, 0, 0, 0.22);-->
        <!--            display: inline;-->
        <!--          "-->
        <!--          aria-hidden="false"-->
        <!--          aria-label="Versleuteld"-->
        <!--          >unlock</mat-icon-->
        <!--        >-->
        <!--      </span>-->
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div
  *ngIf="isNotification"
  class="notification flex justify-center content-stretch items-stretch flex-row"
>
  <mat-card appearance="outlined">
    {{ message.message }}
    <div class="text-right time">
      {{ message.created_at | date : "MMM d, y HH:mm" }}
    </div>
  </mat-card>
</div>
