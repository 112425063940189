import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  dashboardType: string;
  constructor() {}

  // Storage functions CRUD
  setStorageObject(key, value) {
    this.setStorageObjectLocal(key, value);

    return value;
  }

  getStorageObject(key, defaultValue: any = false) {
    if (localStorage.getItem(key) !== undefined) {
      return JSON.parse(localStorage.getItem(key));
    }
    return defaultValue ? defaultValue : false;
  }

  setStorageObjectLocal(key, value) {
    localStorage.setItem(key, JSON.stringify(value));

    return value;
  }

  getStorageObjectLocal(key, defaultValue: any = false) {
    if (localStorage.getItem(key) !== undefined) {
      return JSON.parse(localStorage.getItem(key));
    }

    return defaultValue ? defaultValue : false;
  }

  removeStorage(key) {
    localStorage.removeItem(key);
  }

  clearAllStorage() {
    localStorage.clear();
  }

  flushCache() {}
}
