<div [class]="config.app">
  <h1 mat-dialog-title>{{ "CHANGE_PASSWORD.TITLE" | translate }}</h1>
  <div mat-dialog-content>
    <!-- <p style="margin-bottom: 24px">
      {{ "CHANGE_PASSWORD.WARNING_PGP" | translate }}
    </p> -->
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>{{
          "CHANGE_PASSWORD.CURRENT_PASSWORD" | translate
        }}</mat-label>
        <input
          matInput
          type="password"
          formControlName="currentPassword"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "CHANGE_PASSWORD.NEW_PASSWORD" | translate }}</mat-label>
        <input
          matInput
          type="password"
          formControlName="newPassword"
          required
        />
      </mat-form-field>
      <div
        *ngIf="
          passwordErrors.length > 0 &&
          passwordForm.get('newPassword').value.length > 0
        "
        class="password-errors"
      >
        <p>{{ "CHANGE_PASSWORD.PASSWORD_REQUIREMENTS" | translate }}:</p>
        <ul>
          <li *ngFor="let error of passwordErrors" class="error-message">
            {{ error }}
          </li>
        </ul>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>{{
          "CHANGE_PASSWORD.CONFIRM_PASSWORD" | translate
        }}</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirmPassword"
          required
        />
      </mat-form-field>
      <div *ngIf="passwordForm.hasError('mismatch')" class="error-message">
        {{ "CHANGE_PASSWORD.PASSWORD_MISMATCH" | translate }}
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onCancel()">
          {{ "BUTTON_CANCEL" | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!passwordForm.valid"
        >
          {{ "BUTTON_SAVE" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
