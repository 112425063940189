<div [class]="config.app">
  <div>
    <h1 mat-dialog-title>Setup Encryption</h1>
    <div mat-dialog-content>
      <h2>Change Encryption Key</h2>
      <p>
        You can change the encryption key for your data. This will encrypt
        future data with the new key. Old data remains encrypted with the old
        key and cannot be decrypted with the new key.
      </p>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>current PIN</mat-label>
          <input
            matInput
            type="password"
            [(ngModel)]="currentPin"
            placeholder="Password"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>New PIN</mat-label>
          <input
            matInput
            type="password"
            [(ngModel)]="newPin"
            autocomplete="new-password"
            placeholder="Password"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Repeat new PIN</mat-label>
          <input
            matInput
            type="password"
            [(ngModel)]="newPin2"
            autocomplete="new-password"
            placeholder="Repeat password"
          />
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onDismiss()">Cancel</button>
      <button
        [disabled]="
          encryptionService.serverKeyPassphrase !== currentPin ||
          newPin !== newPin2 ||
          newPin.length < 4
        "
        mat-raised-button
        color="primary"
        (click)="onConfirm()"
      >
        Save
      </button>
    </div>
  </div>
</div>
