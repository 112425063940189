/* src/app/shared/list-user/list-user.component.scss */
mat-list-item {
  --mdc-list-list-item-three-line-container-height: 50px;
  height: 56px;
  padding-left: 0px;
  padding-right: 0px;
}
mat-list-item img {
  width: 40px;
  height: 40px;
}
mat-list-item .name-statuses {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
mat-list-item .name-statuses .name-lastmessage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
mat-list-item .name-statuses .name-lastmessage .name {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #2b2828;
}
mat-list-item .name-statuses .name-lastmessage .last-message {
  font-size: 13px;
  color: #959595;
  margin-top: 4px;
}
mat-list-item .name-statuses .name-lastmessage .last-message.typing {
  color: #ccc;
  font-style: italic;
}
mat-list-item .name-statuses .statuses {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
mat-list-item .name-statuses .statuses .unread {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: right;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  line-height: 20px;
  margin-right: 10px;
  margin-left: 4px;
  background-color: #759fbc;
  color: #fff !important;
  font-size: 14px;
}
mat-list-item .name-statuses .statuses mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
  float: right;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  line-height: 20px;
  color: #959595 !important;
  font-size: 24px;
}
mat-list-item .name-statuses .statuses mat-icon.unwanted {
  color: rgb(239, 89, 89) !important;
}
a {
  height: 50px !important;
  background: #fff;
}
/*# sourceMappingURL=list-user.component-OUCAUZZI.css.map */
