/* src/app/shared/info-message-dialog/info-message-dialog.component.scss */
.time-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mat-mdc-card-title {
  font-size: 15px;
}
mat-card {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 6px;
}
textarea {
  width: 100%;
}
table {
  width: 100%;
}
table th,
table td {
  width: 1%;
}
h3 {
  margin-bottom: 2px;
}
/*# sourceMappingURL=info-message-dialog.component-DP72FNCA.css.map */
