import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/collections";
import { AppConfigService } from "../../app-config.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { KeyValue } from "@angular/common";

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class InfoDialogModel {
  constructor(public title: string, public html: string) {}
}

export interface Filter {
  name: string;
  from: string;
  to: string;
  active: boolean;
}

@Component({
  selector: "app-info-message-dialog",
  templateUrl: "./info-message-dialog.component.html",
  styleUrls: ["./info-message-dialog.component.scss"],
})
export class InfoMessageDialogComponent implements OnInit {
  title: string;
  html: string;

  constructor(
    public config: AppConfigService,
    public dialogRef: MatDialogRef<InfoMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogModel
  ) {
    this.title = data.title;
    this.html = data.html;
  }

  ngOnInit() {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
