<div [class]="config.app">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>

  <div mat-dialog-content>
    <p [innerHTML]="html"></p>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">OK</button>
  </div>
</div>
