<mat-dialog-content [class]="config.app">
  <div *ngIf="!auth.awayMessage">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="auth.awayMessage">
    <!-- <h1 mat-dialog-title>{{ "AUTOMATIC_RESPONSE.TITLE" | translate }}</h1> -->
    <div>
      <h2>{{ "AUTOMATIC_RESPONSE.SCHEDULED_TITLE" | translate }}</h2>
      <div>
        <mat-slide-toggle
          [(ngModel)]="auth.awayMessage.information_message_active"
        ></mat-slide-toggle>
        <table>
          <!-- loop auth.env.languages -->
          <tr *ngFor="let lang of auth.env.languages">
            <td>
              <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
              <quill-editor
                [disabled]="!auth.awayMessage.information_message_active"
                [(ngModel)]="auth.awayMessage.information_message[lang]"
              ></quill-editor>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <h2>{{ "AUTOMATIC_RESPONSE.DURING_WORKING_HOURS_TITLE" | translate }}</h2>
      <div>
        <mat-slide-toggle
          [(ngModel)]="auth.awayMessage.away_message_active"
        ></mat-slide-toggle>
      </div>
      <h3>{{ "AUTOMATIC_RESPONSE.DURING_WORKING_DAYS" | translate }}</h3>
      <table>
        <tr *ngFor="let lang of auth.env.languages">
          <td>
            <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
            <quill-editor
              [disabled]="!auth.awayMessage.away_message_active"
              [(ngModel)]="auth.awayMessage.away_message_weekday[lang]"
            ></quill-editor>
          </td>
        </tr>
      </table>

      <h3>{{ "AUTOMATIC_RESPONSE.DURING_WEEKEND" | translate }}</h3>
      <table>
        <tr *ngFor="let lang of auth.env.languages">
          <td>
            <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
            <quill-editor
              [disabled]="!auth.awayMessage.away_message_active"
              [(ngModel)]="auth.awayMessage.away_message_weekend[lang]"
            ></quill-editor>
          </td>
        </tr>
      </table>
    </div>
    <h2>{{ "AUTOMATIC_RESPONSE.DEFINE_WORKING_HOURS" | translate }}</h2>
    <div *ngIf="auth.awayMessage">
      <mat-card
        appearance="outlined"
        *ngFor="
          let variant of auth.awayMessage.times | keyvalue : originalOrder
        "
      >
        <mat-card-title>
          {{
            "APP.DAYS." +
              (variant.value.translation_key ??
                (variant.value.name_en | uppercase)) | translate
          }}
        </mat-card-title>
        <mat-card-content>
          <mat-list-item>
            <div class="time-items">
              <div class="">
                <mat-checkbox
                  [(ngModel)]="auth.awayMessage.times[variant.key].active"
                ></mat-checkbox>
              </div>
              <div class="time-item">
                <mat-label>
                  {{ "AUTOMATIC_RESPONSE.TIME_FROM" | translate }}:
                  <ngx-mat-timepicker
                    [disabled]="!auth.awayMessage.times[variant.key].active"
                    [format]="24"
                    [(ngModel)]="auth.awayMessage.times[variant.key].from"
                    [defaultTime]="'8:00'"
                  ></ngx-mat-timepicker>
                </mat-label>
              </div>
              <div class="time-item">
                <mat-label>
                  {{ "AUTOMATIC_RESPONSE.TIME_TO" | translate }}:
                  <ngx-mat-timepicker
                    [disabled]="!auth.awayMessage.times[variant.key].active"
                    [format]="24"
                    [(ngModel)]="auth.awayMessage.times[variant.key].to"
                    [defaultTime]="'17:00'"
                  ></ngx-mat-timepicker>
                </mat-label>
              </div>
            </div>
          </mat-list-item>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Cancel</button>
  <button
    [disabled]="!auth.awayMessage"
    mat-raised-button
    color="primary"
    (click)="onConfirm()"
  >
    Save
  </button>
</mat-dialog-actions>
