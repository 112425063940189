/* src/app/shared/layout/layout.component.scss */
.navbar-spacer {
  flex: 1 1 auto;
}
.navbar {
  z-index: 2;
}
.navbar-brand {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}
.navbar-container {
  font-family: var(--font);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.navbar-is-mobile .navbar {
  position: fixed;
  z-index: 2;
}
.navbar-sidenav-container {
  flex: 1;
}
.sidenav-content {
  background: #faf9fd;
}
.navbar-is-mobile .navbar-sidenav-container {
  flex: 1 0 auto;
}
.progress-bar-container {
  height: 5px;
}
mat-toolbar {
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.4);
}
mat-toolbar .logo {
  height: 24px;
}
mat-sidenav {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  width: 300px;
}
mat-sidenav h3 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  font-size: 14px !important;
  font-weight: 600;
}
mat-sidenav h3 .subheader-icon {
  font-size: 17px;
  color: var(--primary-color);
  height: 17px;
  width: 17px;
  margin-right: 12px;
  margin-bottom: 4px;
}
mat-sidenav .current-user {
  margin: 18px 0 0;
}
mat-sidenav .current-user h3 {
  font-size: 1em;
  font-weight: bold;
  color: #2b2828;
}
mat-sidenav .current-user .status-change {
  text-align: left;
}
mat-sidenav .current-user .status-change mat-icon {
  vertical-align: inherit;
}
mat-sidenav .current-user .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: 50% 50%;
  background-color: var(--primary-color);
}
mat-sidenav .available-list,
mat-sidenav .unavailable-list {
  padding: 0 16px;
}
mat-sidenav .available-list .item,
mat-sidenav .unavailable-list .item {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  width: 40px;
  height: 40px;
}
mat-sidenav .available-list .item:after,
mat-sidenav .unavailable-list .item:after {
  content: "";
  right: -2px;
  top: -2px;
  border-radius: 6px;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
mat-sidenav .available-list .item.available:after,
mat-sidenav .unavailable-list .item.available:after {
  background-color: #c8d6af;
}
mat-sidenav .available-list .item.unavailable:after,
mat-sidenav .unavailable-list .item.unavailable:after {
  background-color: #c17142;
}
mat-sidenav .available-list .item .avatar,
mat-sidenav .unavailable-list .item .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-color: var(--primary-color);
}
mat-sidenav .mat-nav-list {
  padding: 8px;
  padding-bottom: 70px;
}
mat-sidenav .mat-nav-list h3 {
  margin-top: 20px;
  font-size: 1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.4px;
  color: #2b2828;
}
.logging-in {
  position: fixed;
  padding-top: 20%;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  height: 100vh;
  text-align: center;
  width: 100%;
}
.logging-in mat-spinner {
  margin: 50px auto;
}
.material-icons {
  color: var(--primary-color);
}
button {
  height: 50px;
}
button .material-icons {
  margin-right: 16px;
  object-fit: contain;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-color);
}
button .large-icon {
  height: 22px;
  font-size: 22px;
  width: auto;
  margin-right: 12px;
  margin-bottom: 2px;
}
button .text {
  display: inline-block;
}
.range {
  font-size: 14px;
}
mat-button-toggle {
  border: 0 !important;
  font-family: var(--font-2);
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2828;
}
mat-button-toggle.mat-button-toggle-checked.button-available {
  background-color: #c8d6af;
}
mat-button-toggle.mat-button-toggle-checked.button-unavailable {
  background-color: #c17142;
  color: #fff;
}
mat-sidenav {
  background-color: #fff;
  font-family: var(--font);
}
h2.mat-mdc-subheader {
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--font-3);
  font-weight: 500;
}
h2.mat-mdc-subheader mat-icon {
  margin-right: 6px;
}
h3.mat-mdc-subheader {
  font-size: 13px;
  font-family: var(--font-3);
  color: rgba(0, 0, 0, 0.54);
  font-weight: 200;
  margin-bottom: 12px;
}
.mat-pseudo-checkbox {
  display: none !important;
}
.availability {
  display: flex;
  align-items: center;
  justify-content: center;
}
mat-nav-list {
  padding-bottom: 60px;
}
/*# sourceMappingURL=layout.component-6UPH65LK.css.map */
