/* src/app/shared/chat-message/chat-message.component.scss */
.mat-mdc-card {
  box-shadow: none !important;
  margin-top: 4px;
  font-family: var(--font-2);
  font-size: 14px;
  border-radius: 2px;
  width: 100%;
  max-width: 520px;
}
.message-divider {
  margin-top: 14px;
}
.time,
.consultant-name {
  position: relative;
  margin-top: 6px;
  font-size: 0.8em;
}
.consultant {
}
.consultant:after {
  content: "";
  display: block;
  width: 17px;
  height: 12px;
  background: url(/assets/img/balloon_consultant.png);
  background-size: 100% 100%;
  margin-top: 20px;
}
.consultant mat-card {
  background-color: rgba(138, 162, 169, 0.6);
}
.consultant mat-card .time,
.consultant mat-card .consultant-name {
  color: #fff;
}
.consultant mat-card .consultant-name {
  margin-bottom: 4px;
}
.notification {
  border-radius: 0;
}
.notification mat-card {
  width: 70%;
  margin: 24px auto;
  border: 1px solid #777;
  font-size: 12px;
  color: #777;
}
.notification mat-card .time {
  margin-top: 12px;
  color: #999;
}
.app-user {
}
.app-user:before {
  content: "";
  display: block;
  width: 19px;
  height: 17px;
  background: url(/assets/img/balloon_user.png);
  background-size: 100% 100%;
  margin-top: 20px;
}
.app-user mat-card {
  border: 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
}
.app-user mat-card .time {
  color: #999;
}
.webchat.app-user {
}
.webchat.app-user mat-card {
  background-color: rgba(138, 162, 169, 0.6);
}
.webchat.app-user mat-card .time,
.webchat.app-user mat-card .consultant-name {
  color: #fff;
}
.webchat.app-user mat-card .consultant-name {
  margin-bottom: 4px;
}
.webchat.app-user:before {
  display: none;
}
.webchat.app-user:after {
  content: "";
  display: block;
  width: 17px;
  height: 12px;
  background: url(/assets/img/balloon_consultant.png);
  background-size: 100% 100%;
  margin-top: 20px;
}
.webchat.consultant {
}
.webchat.consultant mat-card {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2) !important;
}
.webchat.consultant mat-card .time,
.webchat.consultant mat-card .consultant-name {
  color: #626262;
  margin-bottom: 6px;
}
.webchat.consultant:after {
  display: none;
}
.webchat.consultant:before {
  background-color: #fff;
  content: "";
  display: block;
  width: 19px;
  height: 17px;
  background: url(/assets/img/balloon_user.png);
  background-size: 100% 100%;
  margin-top: 20px;
}
.read {
  color: #6a6a6a;
}
.message-date {
  text-align: center;
  margin: 20px 0 20px;
}
.message-date span {
  color: #6a6a6a;
  height: 20px;
  padding: 4px 20px;
  border-radius: 10px;
  font-size: 14px;
  background-color: rgba(117, 159, 188, 0.31);
}
.unwanted-behavior {
  border: 1px solid #000;
  padding: 12px;
  background: rgba(245, 129, 129, 0.38);
  display: block;
  margin-bottom: 16px;
}
.unwanted-behavior-text {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 6px;
}
.word {
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #dcdcdc;
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 4px;
}
.confidant {
  padding: 6px;
}
.confidant strong {
  display: block;
  margin-bottom: 6px;
  margin-top: 12px;
}
.confidant .feeling {
  display: inline-block;
}
.appointment-consultant {
  margin-bottom: 12px;
}
/*# sourceMappingURL=chat-message.component-JTIGIA7K.css.map */
