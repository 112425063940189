import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";

import { AuthenticationService } from "../services/auth.service";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthenticationService
  ) {}

  canActivate() {
    if (this.authService.dashboardType === "webchat") {
      return true;
    }

    const user = this.authService.getCurrentUser();
    if (user && user.expiration) {
      if (moment() < moment(user.expiration)) {
        return true;
      } else {
        console.log("ERROR, Expired", user);
        this.notificationService.openSnackBar("Your session has expired");
        this.router.navigate(["auth/login"]);
        return false;
      }
    } else {
      console.log("No expiration!", user);
    }

    this.router.navigate(["auth/login"]);
    return false;
  }
}
