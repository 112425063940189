/* src/app/shared/change-password-dialog/change-password-dialog.component.scss */
:host {
  display: block;
}
.mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 16px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
mat-form-field {
  width: 100%;
}
.mat-mdc-dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
button {
  margin-left: 8px;
}
.mat-mdc-card-title {
  font-size: 15px;
}
mat-card {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 6px;
}
h3 {
  margin-bottom: 2px;
}
.password-errors {
  background-color: #fff3f3;
  border: 1px solid #ffcccb;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
}
.password-errors p {
  margin: 0 0 8px 0;
  font-weight: bold;
  color: #d32f2f;
}
.password-errors ul {
  margin: 0;
  padding-left: 20px;
}
.password-errors .error-message {
  color: #d32f2f;
  font-size: 14px;
  line-height: 1.4;
}
.error-message {
  color: #d32f2f;
  font-size: 14px;
  margin-top: -8px;
  margin-bottom: 8px;
}
/*# sourceMappingURL=change-password-dialog.component-GGI2B3FW.css.map */
