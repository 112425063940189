import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-content-placeholder-animation",
  templateUrl: "./content-placeholder-animation.component.html",
  styleUrls: ["./content-placeholder-animation.component.scss"],
})
export class ContentPlaceholderAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
