import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  app = environment.app;
  socketUrl = environment.socketUrl;
  appLogo = environment.appLogo;
}
