import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  Inject,
  ViewChild,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { Subscription, timer } from "rxjs";
import { AuthenticationService } from "../../core/services/auth.service";
import { SpinnerService } from "../../core/services/spinner.service";
import { AppConfigService } from "../../app-config.service";
import { environment } from "../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { AwayMessageDialogComponent } from "../away-message-dialog/away-message-dialog.component";
import { StorageService } from "../../storage.service";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AuthGuard } from "../../core/guards/auth.guard";
import { EncryptionSetupDialogComponent } from "../encryption-setup-dialog/encryption-setup-dialog.component";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";
import { EncryptionService } from "src/app/encryption.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  app = environment.app;
  allowAwayMessage = environment.allowAwayMessage;
  isProduction = environment.production;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  showSpinner: boolean;
  userName: string;
  isAdmin: boolean;
  currentUser: any;
  objectKeys = Object.keys;
  private autoLogoutSubscription: Subscription;
  @ViewChild("snav") snav;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public config: AppConfigService,
    public spinnerService: SpinnerService,
    public authService: AuthenticationService,
    public encryptionService: EncryptionService,
    private authGuard: AuthGuard,
    private afMessaging: AngularFireMessaging,
    public dialog: MatDialog,
    public storage: StorageService
  ) {
    this.mobileQuery = this.media.matchMedia("(max-width: 1000px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    const token = this.storage.getStorageObject("push-token", false);
    if (token) {
      this.requestPermission();
    }
  }

  requestPermission() {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then((registration) => {
        this.afMessaging.requestPermission.subscribe(
          (token) => {
            this.storage.setStorageObject("push-token-accepted", true);
            this.storage.setStorageObject("push-token", token);
            if (this.authService.io) {
              this.authService.io.emit("push-token", token);
              this.authService.showNotificationNotice = false;
            }
          },
          (error) => {
            console.error(error);
          }
        );
        this.afMessaging.tokenChanges.subscribe((newToken) => {
          if (newToken) {
            this.storage.setStorageObject("push-token-accepted", true);
            this.storage.setStorageObject("push-token", newToken);
            if (this.authService.io) {
              this.authService.io.emit("push-token", newToken);
              this.authService.showNotificationNotice = false;
            }
          }
        });
        this.afMessaging.getToken.subscribe((token) => {
          if (token) {
            this.storage.setStorageObject("push-token-accepted", true);
            this.storage.setStorageObject("push-token", token);
            if (this.authService.io) {
              this.authService.io.emit("push-token", token);
              this.authService.showNotificationNotice = false;
            }
          }
        });
      });
  }

  asIsOrder(a, b) {
    return 1;
  }

  ngOnInit(): void {
    const user = this.authService.getCurrentUser();

    if (user) {
      this.isAdmin = user.isAdmin;
      this.userName = user.fullName;
      this.currentUser = user;

      // Auto log-out subscription
      const _timer = timer(2000, 5000);
      this.autoLogoutSubscription = _timer.subscribe((t) => {
        this.authGuard.canActivate();
      });
    } else {
      this.authService.logout();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.autoLogoutSubscription) {
      this.autoLogoutSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  changeStatus(newStatus) {
    this.showSpinner = true;
  }

  logout() {
    this.authService.logout();
    window.location.reload();
  }

  setEncryption() {
    const dialogRef = this.dialog.open(EncryptionSetupDialogComponent, {
      width: "600px",
      data: {
        title: "",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.authService.io.emit("away-message-settings", result);
      }
    });
  }

  setAwayMessage() {
    const dialogRef = this.dialog.open(AwayMessageDialogComponent, {
      width: "600px",
      height: "600px",
      data: {
        title: "",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.io.emit("away-message-settings", result);
      }
    });
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: "600px",
      data: {
        title: "",
      },
    });
  }

  openMenu() {
    this.snav.toggle();
  }
}
