import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  public openSnackBar(message: string, time = 1500) {
    this.snackBar.open(this.translateService.instant(message), "", {
      duration: time,
    });
  }
}
