/* src/app/shared/away-message-dialog/away-message-dialog.component.scss */
.time-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mat-mdc-card-title {
  font-size: 15px;
}
mat-card {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 6px;
}
textarea {
  width: 100%;
}
table {
  width: 100%;
}
table th,
table td {
  width: 1%;
}
h3 {
  margin-bottom: 2px;
}
quill-editor {
  width: 100%;
  max-height: 175px;
}
quill-editor[disabled] {
  background-color: #f3f3f3;
  opacity: 0.4;
  cursor: not-allowed;
}
/*# sourceMappingURL=away-message-dialog.component-UFUZ24K2.css.map */
