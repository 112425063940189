<div [class]="config.app">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>

  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">
      {{ "BUTTON_YES" | translate }}
    </button>
    <button mat-button (click)="onDismiss()">
      {{ "BUTTON_CANCEL" | translate }}
    </button>
  </div>
</div>
