<div class="title ion-margin-top" *ngIf="bubbles.length && translation">
  <div size="12">
    <h1
      [translate]="translation"
      [translateParams]="{
        NUM: bubbles.length > 10 ? 10 : bubbles.length
      }"
    ></h1>

    <div class="center ion-margin-top masonry-container">
      <ngx-masonry #masonry [options]="options" [ordered]="false">
        <div
          ngxMasonryItem
          *ngFor="let item of bubbles; let index = index"
          class="circle-container x{{ item.num }}"
          [style.paddingLeft]="item.marginLeft + 'px'"
          [style.paddingRight]="item.marginRight + 'px'"
          [style.paddingBottom]="item.marginBottom + 'px'"
        >
          <div class="circle {{ item.type }} x{{ item.num }}">
            <div
              class="circle-inner"
              #container
              (click)="showBubble[index] = !showBubble[index]"
            >
              <div class="num">{{ item.num }}x</div>
              <div
                [class]="'name loaded ' + (showBubble[index] ? ' show' : '')"
              >
                <div [innerText]="item.text"></div>
              </div>
            </div>
          </div>
        </div>
      </ngx-masonry>
    </div>
  </div>
</div>
